body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import "~antd/dist/antd.css";

a{
  color: white;
}


@font-face {
  font-family: "Neue Montreal";
  src: local("Neue Montreal"),
  url("./assets/fonts/Neue-montreal-free-demo_pangram-pangram_030418/NeueMontreal-Medium.otf") format("opentype");
}


